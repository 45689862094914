<template>
  <div class="live-claim-page">
    <div v-if="promoData && typeData && initialLoadComplete" class="live-claim">

      <div class="live-claim__header">
        <transition name="fade" mode="out-in">
          <MediaContainer
            v-if="alreadyClaimed || claimSuccess"
            key="claimed"
            autoplay
            :imagePath="tokenImagePath(promoData.collection, promoData.featuredType, 540, true, 'png')"
            :videoPath="
              typeData.video &&
              tokenVideoPath(promoData.collection, promoData.featuredType, 540)
            "
            :size="540"
          />
          <img
            v-else
            key="unclaimed"
            :src="tokenImagePathUnclaimed(promoData.collection, promoData.featuredType, 540)"
            class="unclaimed"
            :alt="typeData.name"
          />
        </transition>
        <transition name="fade" mode="out-in">
          <template v-if="!alreadyClaimed">
            <h4
              v-if="!claimSuccess"
              class="lockup-top"
              key="found"
              :style="`color: ${accentColor}`"
            >
              You Found
            </h4>
            <h4
              v-else
              class="lockup-top"
              key="collected"
              :style="`color: ${accentColor}`"
            >
              Success!<br>You Collected
            </h4>
          </template>
        </transition>
        <img
          src="@/assets/images/events/movement-22/ghettotechtopia_title.png"
          alt="Ghettotechtopia"
          class="title-image"
        >
        <h4 :style="accentColor && `color: ${accentColor}`">{{ typeData.name }}</h4>
      </div>

      <!-- The claim UI should only be shown if the user hasn't already received one -->
      <template v-if="!alreadyClaimed">
        <form v-if="!claimSuccess" class="live-claim__form" @submit.prevent="submitClaimForm">
          <template v-if="!currentUser">
            <TextInput v-model="claimEmail" type="email" placeholder="Enter Email to Collect" />
            <div v-if="!alreadyAgreed" class="legal">
              <v-checkbox v-model="agreed" :ripple="false" hide-details dark />
              <p>
                By collecting this NFT you are agreeing to the
                <router-link to="/terms" @click.native="closeLogin">Terms of Service</router-link> and <router-link to="/privacy" @click.native="closeLogin">Privacy Policy</router-link>.
              </p>
            </div>
            <button class="standard" :disabled="!claimEmail || !agreed">Submit</button>
          </template>
          <template v-else>
            <button class="standard">Claim Now</button>
          </template>
        </form>

        <transition name="fade">
          <div v-if="claimSuccess && !currentUser" class="success-text">
            <p>Check your inbox and follow the link to sign up for a free account. Once signed in, you'll be able to view your NFT portfolio, hear new music, and claim NFTs even faster.</p>
          </div>
        </transition>
      </template>

      <!-- UI to show if user already has this one -->
      <template v-else>
        <h4 class="already-claimed">You've already collected this one!</h4>
        <p>There are more QR codes hidden around the festival. Scan another to try again.</p>
      </template>

      <!-- If the user already has it, or just claimed it, direct them to their portfolio -->
      <template v-if="claimSuccess || alreadyClaimed">
        <div class="sharing">
          <p>Let others know you found it!
            <span class="sharing__icons">
              <a :href="`https://twitter.com/intent/tweet?text=Check%20this%20out!&url=${shareLink}`" target="_blank">
                <v-icon>mdi-twitter</v-icon>
              </a>
              <a :href="`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </a>
            </span>
          </p>
        </div>
        <template v-if="currentUser">
          <p>Please note that it may take a few minutes for your recently minted NFT to appear in your portfolio.</p>
          <router-link to="/portfolio">
            <button class="standard">
              View your Portfolio
            </button>
          </router-link>
        </template>
      </template>

      <div class="live-claim__footer">
        <img src="@/assets/images/events/movement-22/movement_22_banner.jpg" alt="Movement 22" />
        <h4 class="event-title">What is This?</h4>
        <router-link class="learn-more" to="/movement-22">Learn More</router-link>
      </div>

      <PresentedBy />
    </div>
  </div>
</template>

<script>
import { doc, getDoc } from 'firebase/firestore'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import PresentedBy from '@/components/movement-22/PresentedBy'

export default {
  components: {
    PresentedBy
  },
  data () {
    return {
      initialLoadComplete: false,
      promoData: null,
      typeData: null,
      alreadyAgreed: null,
      agreed: false,
      alreadyClaimed: false,
      claimEmail: null,
      claimError: null,
      claimSuccess: false,
      accentColor: null
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'loading']),
    shareLink () {
      return `${window.location.origin}/market/${this.promoData.collection}/${this.typeData.typePrimary.id}/${this.typeData.typeSecondary.id}`
    }
  },
  async mounted () {
    this.setLoading(true)
    try {
      await this.userAuthCheck()
    } catch (err) {
      console.log(err)
    }
    this.gatherLocalData()
    await this.getRemoteData()
    await this.checkClaimOwnership()
    this.setLoading(false)
    this.initialLoadComplete = true
  },
  methods: {
    ...mapActions([
      'userAuthCheck'
    ]),

    ...mapMutations([
      'setLoading',
      'setGlobalState',
      'setShowingLogin'
    ]),

    /**
     * Gather any relevant preexisting data in local storage.
     */
    gatherLocalData () {
      // If logged in, set the claim email to the user's account email
      if (this.currentUser) {
        console.log('A user is currently logged in. Using currentUser email.')
        this.claimEmail = this.currentUser.email
      } else {
        console.log('No authenticated user. Using localStorage email')
        // Otherwise, see if it exists in local storage
        this.claimEmail = window.localStorage.getItem('claimEmail')
      }
      // If the user has already agreed, we don't need to show this again.
      this.alreadyAgreed = window.localStorage.getItem('liveClaimTermsAgreed')
      this.agreed = this.alreadyAgreed || false
    },

    /**
     * Get relevant data for the page, including the promotion information and the token to be claimed.
     */
    async getRemoteData () {
      try {
        const targetPromo = await getDoc(doc(this.$fb.db, 'promotions', this.$route.params.promo))
        this.promoData = targetPromo.data()
      } catch (err) {
        this.addNotification('Error retrieving promotion data.', 'error')
      }
      try {
        const targetType = await getDoc(
          doc(this.$fb.db, 'token-contracts', this.promoData.collection, 'types', this.promoData.featuredType)
        )
        this.typeData = targetType.data()
        if (this.typeData.attributes) {
          const colorAttr = this.typeData.attributes.filter(obj => {
            return obj.trait_type === 'color'
          })
          if (colorAttr && colorAttr[0]) {
            this.accentColor = colorAttr[0].value
          }
        }
      } catch (err) {
        console.log(err)
        this.addNotification('Error retrieving token data.', 'error')
      }
    },

    /**
     * Check the claim records on the promotion to see if the user has already received.
     * By attaching the claim records to the promo rather than the user,
     * both authenticated and unauthenticated users are accounted for in one location.
     */
    async checkClaimOwnership () {
      if (!this.claimEmail) return
      this.alreadyClaimed = await this.checkPromotionClaimOwnership(this.$route.params.promo, this.claimEmail)
    },

    /**
     * Called when the user submits the claim form,
     * either by entering their email or clicking the claim now button if they're already logged in.
     */
    async submitClaimForm () {
      this.setLoading(true)
      this.setGlobalState({ target: 'loadingStatus', val: 'Generating your claim...' })
      console.log(`Checking promotion ${this.$route.params.promo}`)
      window.localStorage.setItem('claimEmail', this.claimEmail)
      try {
        const res = await this.fbCall('promotions-checkForUser', {
          targetPromo: this.$route.params.promo,
          targetEmail: this.claimEmail
        })
        // Save the user's email for later so they don't need to enter it again
        window.localStorage.setItem('liveClaimTermsAgreed', true)
        console.log(`Generated claim ${res.data.claimId}`)
        // If the user is logged in, go ahead and mint the token as well.
        if (this.currentUser) {
          try {
            this.fbCall('claiming-claimToken', {
              claimId: res.data.claimId
            })
          } catch (err) {
            console.log('Error minting token.')
          }
        } else {
          this.sendLoginEmail()
        }
        this.claimSuccess = true
      } catch (err) {
        await this.checkClaimOwnership()
        if (!this.alreadyClaimed) {
          this.addNotification(err.message || 'Sorry, there was an error generating your claim.', 'error', false)
        }
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      this.setLoading(false)
    },

    /**
     * If the user isn't already logged in, send them a login email.
     * TODO: Make targetTemplate and redirectUrl variable based on event data attached to promo
     */
    async sendLoginEmail () {
      try {
        window.localStorage.setItem('emailForSignIn', this.claimEmail)
        await this.fbCall('sendgrid-sendSignInLinkToEmail', {
          email: this.claimEmail,
          redirectUrl: `${window.location.origin}/movement-22`,
          targetTemplate: 'template_id_movement_22',
          targetCollection: this.promoData.collection,
          targetType: this.promoData.featuredType,
          timestamp: new Date().toLocaleString()
        })
      } catch (err) {
        console.log('Error sending login email:', err)
        this.addNotification(err.message, 'error')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.live-claim-page {
  max-width: 540px;
  margin: 0 auto;
  text-align: center;
}
.login-link {
  text-decoration: underline;
}
.live-claim {
  p {
    font-family: $font-title;
    margin-bottom: $space-m;
  }
  button {
    width: 100%;
  }
  &__header {
    margin: 0 0 $space-m;
    .media-container {
      margin-bottom: $space-m;
    }
    .unclaimed {
      width: 100%;
      margin-bottom: $space-m;
      opacity: 0.5;
    }
    .lockup-top {
      margin-bottom: $space-xs;
    }
    .title-image {
      width: 100%;
    }
  }
  &__form {
    ::v-deep input {
      text-align: center;
      font-size: $font-size-m;
      color: $blk-black;
      background-color: $blk-white;
      border: none;
      padding: $space-ms 0;

      &::-webkit-input-placeholder {
        font-size: 20px;
        text-transform: uppercase;
      }
    }
    .legal {
      margin: $space-ml 0;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      text-align: left;

      p {
        margin-bottom: 0;
      }

      .v-input--checkbox {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
  .already-claimed {
    margin-bottom: $space-m;
  }
  .sharing {
    &__icons {
      a {
        text-decoration: none;
      }
      .v-icon {
        margin-left: $space-s;
        font-size: 20px;
      }
    }
  }
  .success-text {
    h4 {
      margin: $space-ml 0 $space-s;
    }
  }
  &__footer {
    margin-top: $space-xl;
    img {
      width: 100%;
    }
    .event-title {
      margin: $space-l 0 $space-s;
      color: #fdd612;
    }
    .learn-more {
      text-transform: uppercase;
    }
  }
}
</style>
